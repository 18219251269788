import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const self = this;
    this.element.querySelector('#filter-toggle').onclick = () => {
      self.element.classList.toggle('collapse-show')
    }
  }
}
