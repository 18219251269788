const fetchLiveImage = (cameraId, imgElement, { onLoad, onError } = {}) => {
  Api.toDataURL(Routes.liveSnapshot(cameraId), (dataUrl) => {
    const img = new Image()

    img.onload = () => {
      imgElement.style.backgroundImage = `url(${dataUrl})`;
      if (onLoad) { onLoad(dataUrl) }
    }

    img.onerror = (e) => {
      if (onError) { onError(e) }
    }

    img.src = dataUrl
  }, (xhr) => { })
}

export const ImageHelper = {
  fetchLiveImage
}
