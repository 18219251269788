class MJPEGPlayer {
  static get JPEG_MAGIG_NUMBER() {
    return [0xff, 0xd8, 0xff];
  }

  constructor(parentElement, mjpegUrl, snapshotUrl) {
    this.element  = parentElement;
    this.mjpegUrl = mjpegUrl;
    this.jpegFiles = [];
    this.frameIndex = 0;
    this.fps        = 15;
    this.timerId    = null;
    this.image      = document.createElement('img')
    this.onSliderChage = new CustomEvent('sliderChange')

    this.image.setAttribute('src', snapshotUrl)
    this.image.setAttribute('style', 'max-width: 100%; max-height: 100%');
    this.image.addEventListener('click', this.play.bind(this))
    this.element.appendChild(this.image);
    // this.element.addEventListener('click', this.stopOrPlay.bind(this))

    this.render()
  }

  getSlider() {
    if (this.slider) { return this.slider }

    this.slider = document.createElement('input')
    this.slider.classList.add('flex-1')
    this.slider.setAttribute('type', "range")
    this.slider.setAttribute('min', 0);
    this.slider.setAttribute('max', 600);
    this.slider.setAttribute('value', 0);

    // const requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.setTimeout;
    let jpegUrl;

    const onChange = () => {
      const self = this;
      if (this.image) {
        if (jpegUrl) { URL.revokeObjectURL(jpegUrl); }

        const sliderValue = parseInt(this.slider.value)
        jpegUrl = URL.createObjectURL(this.jpegFiles[sliderValue]);

        this.image.onload = () => {
          if (this.image) {
            if (sliderValue < self.jpegFiles.length - 1) {
              self.slider.value = sliderValue + 1;
            } else {
              self.slider.value = 0;
              self.stop();
            }
          }
        };
        this.image.setAttribute('src', jpegUrl);
      }
    }

    this.slider.addEventListener('sliderChange', onChange.bind(this))
    this.slider.addEventListener('change', onChange.bind(this))
    return this.slider
  }

  render() {
    this.element.classList.add('mjpeg-player__container', 'mjpeg-player__stop')

    // Render footer
    const controls = document.createElement('div')
    controls.classList.add('mjpeg-player__controls', 'flex')

    const stopBtn = document.createElement('i')
    stopBtn.classList.add('fas', 'fa-pause', 'me-2')
    stopBtn.addEventListener('click', this.stop.bind(this))
    controls.appendChild(stopBtn)

    const playBtn = document.createElement('i')
    playBtn.classList.add('fas', 'fa-play', 'me-2')
    playBtn.addEventListener('click', this.play.bind(this))
    controls.appendChild(playBtn)

    const globalPlayBtn = document.createElement('i')
    globalPlayBtn.classList.add('fas', 'fa-play', 'mjpeg-player__global-play-btn')
    globalPlayBtn.addEventListener('click', this.play.bind(this))
    this.element.appendChild(globalPlayBtn)

    const loadingSpiner = document.createElement('i')
    loadingSpiner.classList.add('fas', 'fa-spinner', 'fa-pulse', 'mjpeg-player__spinner')
    this.element.appendChild(loadingSpiner)

    controls.appendChild(this.getSlider())
    this.element.appendChild(controls)
  }

  play() {
    this.element.classList.remove('mjpeg-player__stop')

    // autoloop = (typeof autoloop === 'boolean') ? autoloop : DEFAULT_AUTOLOOP;
    const self  = this;
    const requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.setTimeout;

    this.load(() => {
      this.fps = this.jpegFiles.length / 60; // 60 - one minute

      if (this.jpegFiles.length > 0) {
        if (this.timerId) { clearInterval(this.timerId); }

        this.timerId = setInterval(function() {
          self.slider.dispatchEvent(self.onSliderChage);
          // requestAnimationFrame(showNextFrame);
        }, 1000 / self.fps);
      }

    });
  }

  stop() {
    this.element.classList.add('mjpeg-player__stop')
    clearInterval(this.timerId);
  }

  load(callback) {
    if (this.jpegFiles.length > 0) { return callback() } // Already loaded

    this.element.classList.add('mjpeg-player__loading')
    const xhr  = new XMLHttpRequest();
          self = this;

    xhr.open('GET', this.mjpegUrl, true);
    xhr.overrideMimeType('application/octet-stream');
    xhr.responseType = 'arraybuffer';

    xhr.onload = function(event) {
      var array = new Uint8Array(xhr.response), startIndex

      for (var i = 0, ii = array.length; i < ii; ++i) {
        if (array[i] === MJPEGPlayer.JPEG_MAGIG_NUMBER[0] && array[i+1] === MJPEGPlayer.JPEG_MAGIG_NUMBER[1] && array[i+2] === MJPEGPlayer.JPEG_MAGIG_NUMBER[2]) {
          if (i > 0 && typeof startIndex === 'number') {
            self.jpegFiles.push(new Blob([array.subarray(startIndex, i)], {type: 'image/jpeg'}));
          }
          startIndex = i;
        }
      }

      self.element.classList.add('mjpeg-player__loaded')
      self.slider.setAttribute('max', self.jpegFiles.length - 1);
      self.element.classList.remove('mjpeg-player__loading')
      callback();
    };

    xhr.send();
  }
}

window.MJPEGPlayer = MJPEGPlayer;