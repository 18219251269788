import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['cameraSelection', 'presetSelect']

  connect() {
    this.timerId = null;
    this.isDisconnected = false;
    this.runSnapshotLiveView();
    this.cameraSelectionTarget.addEventListener('change', () => { this.addLoading(); this.initPtz() })
    this.cameraSelectionTarget.dispatchEvent(new Event('change'));
  }

  addLoading() {
    this.element.classList.add('live-layout__loading')
  }

  removeLoading() {
    this.element.classList.remove('live-layout__loading')
  }

  disconnect() {
    this.isDisconnected = true
  }

  runSnapshotLiveView(delay = 100) {
    if (this.isDisconnected) { return }

    const self = this;

    this.timerId = setTimeout(() => {
      if (!this.cameraSelectionTarget.value) {
        return self.runSnapshotLiveView(1500);
      }

      Api.toDataURL(Routes.liveSnapshot(this.cameraSelectionTarget.value), (dataUrl) => {
        const img = new Image()

        img.onload = () => {
          this.element.querySelector('.image').style.backgroundImage = `url(${dataUrl})`;
          this.removeLoading();
          self.runSnapshotLiveView();
        }

        img.onerror = (e) => {
          self.element.classList.add('liveview-error')
          self.runSnapshotLiveView(1500);
        }

        img.src = dataUrl
      }, (xhr) => {
        this.addLoading();

        // if (xhr.status == 404) {
        //   self.setState({ errors: ['Lost connection to the camera'] })
        // } else {
        //   self.setState({ errors: ['Can\'t connect to the camera'] })
        // }

        self.runSnapshotLiveView(1500)
      })

    // TODO: Make less delay for local? Or change delay according to connection
    }, delay)
  }

  initPtz() {
    const selectedCamera = this.cameraSelectionTarget.selectedOptions[0];
    if (selectedCamera && selectedCamera.dataset.ptz == 'true') {
      this.element.classList.add('ptz')
      this.fetchPtzPresets()
    } else {
      this.element.classList.remove('ptz')
    }
  }

  fetchPtzPresets() {
    Api.post(Routes.cameraPtz(this.cameraSelectionTarget.value, 'presets'), {}, (presets) => {
      presets.forEach(({ id, name, selected }) => {
        const option = document.createElement('option');
        
        option.textContent = name;
        option.value = id;
        option.selected = selected
        this.presetSelectTarget.appendChild(option);
      });
    })
  }

  sendPtzAction(e) {
    const action = e.target.dataset.ptzAction
    let data = {
      ptz_params: {}
    }

    if (action == 'set_preset') {
      data.ptz_params.preset_id = e.target.selectedOptions[0].value
      if (!data.ptz_params.preset_id) return 
    }

    Api.post(Routes.cameraPtz(this.cameraSelectionTarget.value, action), data)
  }
}
