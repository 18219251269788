import { Controller } from "@hotwired/stimulus"
import { ImageHelper } from "../../helpers/ImageHeper";

export default class extends Controller {
  static targets = ['canvas', 'maskFormField', 'image']

  connect() {
    const { cameraId } = this.element.dataset;
    const self = this;
    const onImageLoad = (base64, data) => { 
      self.imageTarget.src = base64
      self.imageTarget.style.width  = `${self.imageTarget.width}px`
      self.imageTarget.style.height = `${self.imageTarget.height}px`

      const context = this.canvasTarget.getContext("2d");
      context.canvas.width  = self.imageTarget.width;
      context.canvas.height = self.imageTarget.height;

      if (self.maskFormFieldTarget.value.length > 0) {
        const image  = new Image();
        image.onload = function() { context.drawImage(image, 0, 0); };
        image.src    = this.maskFormFieldTarget.value;
      }
    }
    ImageHelper.fetchLiveImage(cameraId, this.imageTarget, { onLoad: onImageLoad })
  }

  drawMask = (e) => {
    const context = this.canvasTarget.getContext("2d");

    // переменные для рисования
    context.lineCap = "round";
    context.lineWidth = 20;

    const x = e.offsetX;
    const y = e.offsetY;
    const dx = e.movementX;
    const dy = e.movementY;

    // Проверяем зажата ли какая-нибудь кнопка мыши
    // Если да, то рисуем
    if (e.buttons > 0) {
      context.strokeStyle = 'rgb(255,0,0)';
      context.beginPath();
      context.moveTo(x, y);
      context.lineTo(x - dx, y - dy);
      context.stroke();
      context.closePath();
    }
  }
  
  submitForm = () => {
    this.maskFormFieldTarget.value = this.canvasTarget.toDataURL("image/png")
  }

  clearCanvas = (e) => {
    e.preventDefault();

    const context = this.canvasTarget.getContext('2d');
    context.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
    this.maskFormFieldTarget.value = '';
  }

  downloadMask = (e) => {
    e.preventDefault()
    
    const link = document.createElement('a')
    link.download = "image.png";
    link.href = this.canvasTarget.toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    link.click()
  }
}
