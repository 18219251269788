import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['startsAt', 'endsAt', 'slider', 'sliderDescription', 'cameraId', 'startsAtParam']

  connect() {
    this.isStop = true;


    // debugger
    // moment(timelapse.createdAt).format('YYYY-MM-DD HH:mm:ss')
  }

  onSliderChange(e) {
    this.sliderTime = moment.unix((moment(this.startsAtTarget.value).unix() + parseInt(this.sliderTarget.value) * 5));
    this.startsAtParamTarget.value = this.sliderTime
    this.sliderDescriptionTarget.innerHTML = this.sliderTime.format('YYYY-MM-DD HH:mm:ss');
    this.loadImage(e ? true : false);
  }

  loadImage(once = false) {
    if (this.isStop && !once) { return }
    // TODO: Stop if end of player
    const self = this;

    // debugger
    Api.toDataURL(this.imagePath(), (dataUrl) => {
      const img = new Image()

      img.onload = () => {
        self.element.querySelector('.image').style.backgroundImage = `url(${dataUrl})`;
        if (!once) { self.nextImage() }
      }

      img.onerror = (e) => {
        // alert('failed to load')
        // self.stop();
        if (!once) { self.nextImage() }
      }

      img.src = dataUrl
    }, (xhr) => {
      console.log("Some Error")

      // if (xhr.status == 404) {
      //   self.setState({ errors: ['Lost connection to the camera'] })
      // } else {
      //   self.setState({ errors: ['Can\'t connect to the camera'] })
      // }

      alert("ERROR");
    })
  }

  imagePath() {
    const imagePath = encodeURIComponent(`/${this.cameraIdTarget.value}/${moment(this.sliderTime).format('YYYYMMDD_HH/YYYY-MM-DD HH:mm:ss')}.jpg`);
    return `/cameras/${this.cameraIdTarget.value}/timelapses/download?path=${imagePath}`;
  }

  nextImage() {
    setTimeout(() => {
      this.sliderTarget.value = parseInt(this.sliderTarget.value) + 1
      this.onSliderChange();
    }, 500)
  }

  play(el) {
    this.isStop = false;
    this.element.classList.add('play')
    this.element.classList.remove('stoped')
    this.loadImage()
  }

  stop() {
    this.isStop = true
    this.element.classList.add('stoped')
    this.element.classList.remove('play')
  }
}