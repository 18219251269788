import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element.dataset.videoFormat == 'mjpg') {
      this.renderMJPEGPlayer();
    } else {
      this.renderClaprPlayer();
    }
  }

  renderMJPEGPlayer() {
    const { snapshotPath, source, seek } = this.element.dataset;

    new MJPEGPlayer(this.element, source, snapshotPath)
  }

  renderClaprPlayer() {
    const { snapshotPath, source, seek } = this.element.dataset;

    const player = new Clappr.Player({
      parent: this.element,
      mimeType: 'video/mp4',
      source: source,
      width: '100%',
      height: '100%',
      autoPlay: false,
      poster: snapshotPath,
      preload: 'none',
      exitFullscreenOnEnd: false,
      events: {
        onReady: function() {
          if (Clappr.Browser.isMobile) {
            var plugin = self.getPlugin('click_to_pause');
            plugin && plugin.disable();
          }
        }
        // onPlay: function() {
        //   seek = seek ? parseInt(seek) : 0
        //   this.seek(seek);
        // }
      },
      playback: {
        hlsjsConfig: { maxBufferLength: 5 },
        playInline: true,
        crossOrigin: 'anonymous'
      }
    });

    player.seek(parseInt(seek) || 0);
  }
}
