import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const self = this;
    const input = this.element.querySelector('input, select')

    const activeFunction = (e) => {
      if (input.value.length > 0) {
        self.element.classList.add('active')
      } else {
        self.element.classList.remove('active')
      }
    }

    input.addEventListener('focusin', activeFunction)
    input.addEventListener('focusout', activeFunction)
    input.addEventListener('change', activeFunction)

    activeFunction(input)
  }
}
