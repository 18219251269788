import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var observer = new IntersectionObserver((entries) => {
      // If intersectionRatio is 0, the target is out of view
      // and we do not need to do anything.
      if (entries[0].intersectionRatio <= 0) { return; }

      entries.forEach(entry => {
        Api.toDataURL(entry.target.dataset.src, (base64) => {
          entry.target.src = base64
          entry.target.classList.add('successfuly-loaded')
          entry.target.classList.remove('loading')
        }, () => {
          entry.target.classList.remove('loading')
          entry.target.classList.add('failed-to-loaded')
          console.log("Failed to load image");
        })

        observer.unobserve(entry.target);
      });
    })

    this.element.classList.add('loading')
    observer.observe(this.element);
  }
}
