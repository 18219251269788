import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['entries']

  connect() {
    this.page           = 0;
    this.perPage        = 20;
    this.isLoading      = false;
    this.preventLoading = false;
    this.lastEventDate  = undefined;

    this.downloadContent();
  }

  scroll() {
    if (!this.isLoading && !this.preventLoading && (this.element.offsetHeight - window.pageYOffset) <= window.innerHeight ) {
      this.downloadContent()
    }
  }

  downloadContent() {
    this.loading();
    // TODO: Do not do it only for events page
    Api._get(this.element.dataset.url, this.requestParams(), ({ html, last_event_date }) => {
      this.page         += 1;
      this.lastEventDate = last_event_date;

      if (html) {
        this.entriesTarget.insertAdjacentHTML('beforeend', html)
      } else {
        // When downloaded full list of entries
        this.preventLoading = true;
      }

      this.stopLoading();
    }, (response) => {
      this.stopLoading();
    })
  }

  requestParams() {
    if (this.lastEventDate) {
      return { page: this.page, per_page: this.perPage, last_event_date: this.lastEventDate }
    } else {
      return { page: this.page, per_page: this.perPage }
    }
  }

  loading() {
    this.isLoading = true;
    this.element.classList.add('loading')
  }

  stopLoading() {
    this.isLoading = false;
    this.element.classList.remove('loading')
  }
}
