const BASE_API = '/api/v1'

window.Routes = {
  // events
  events: () => `/events`,

  // live
  liveSnapshot: (cameraId) => `/cameras/${cameraId}/live/snapshot?${new Date().getTime()}`,
  cameraPtz: (cameraId, action) => `/cameras/${cameraId}/ptz/${action}`


  // events
  // event: (id) => `${BASE}/events/${id}`,
  // // Timelapses
  // timelapseDownload: (cameraId, timelapsePath) => `${BASE}/cameras/${cameraId}/timelapses/download?local_path=${timelapsePath}`,
  // timelapses: (cameraId) => `${BASE}/cameras/${cameraId}/timelapses`,

  // // Recordings
  // recording: (cameraId, recordingName) => `${BASE}/cameras/${cameraId}/recordings/${recordingName}`,
  // recordingsIndex: (cameraId) => `${BASE}/cameras/${cameraId}/recordings`,

  // // Live
  // livePlaylist: (cameraId) => `${BASE}/cameras/${cameraId}/live/playlist.m3u8`,
  // liveSnapshot: (cameraId) => `${BASE}/cameras/${cameraId}/live/snapshot?${new Date().getTime()}`,

  // // Cameras
  // cameras: () => `${BASE}/cameras`,
  // camera: (id) => `${BASE}/cameras/${id}`,

  // // Sessions
  // login: () => `${BASE}/sessions`,

  // // Users
  // userInfo: () => `${BASE}/users/info`,

  // // ABox
  // deploy: () => `${BASE}/a_boxes/deploy`,
  // aBoxes: () => `${BASE}/a_boxes`,
  // aBox: (id) => `${BASE}/a_boxes/${id}`,
  // aBoxSynchronize: (id) => `${BASE}/a_boxes/${id}/synchronize`,
  // stats: (id) => `${BASE}/a_boxes/${id}/stats`,

  // // Companies
  // companies: () => `${BASE}/companies`
}
