import { Controller } from "@hotwired/stimulus"
import { getCSRFToken } from "../helpers/csrfToken"

export default class extends Controller {
  connect() {
    document.querySelectorAll('.sidebar-toggle').forEach((e) => 
      e.addEventListener('click', () => {
        document.getElementById('sidebar').classList.toggle('collapsed')
      })
    )

    document.querySelector('.theme-toggle').addEventListener(('click'), () => {
      document.body.classList.toggle('dark-theme')

      fetch("/users/set_dark_mode", {
        method: "POST",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      })
    })

    //*** Application Modal scripts */
    const applicationModalEl = document.getElementById('application-modal')
    const copyLinkEl = applicationModalEl.querySelector('#copy-link')
    const applicationModal = new bootstrap.Modal(applicationModalEl)

    applicationModalEl.addEventListener('show.bs.modal', (e) => {
      applicationModalEl.querySelector('#load-spinner-container').classList.remove('hide')
      applicationModalEl.querySelector('#application-modal-frame').innerHTML = ''
    })

    document.getElementById('application-modal-frame').addEventListener('turbo:before-fetch-request', (e) => {
      copyLinkEl.dataset.url = e.detail.url
      applicationModal.show()
    })

    document.getElementById('application-modal-frame').addEventListener('turbo:before-fetch-response', (e) => {
      applicationModalEl.querySelector('#load-spinner-container').classList.add('hide')
    })

    copyLinkEl.addEventListener('click', (e) => {
      const copiedTextEl = copyLinkEl.previousSibling.previousSibling
      copiedTextEl.classList.remove('hide')
      setTimeout(() => { copiedTextEl.classList.add('hide') }, 2000)
      navigator.clipboard.writeText(e.currentTarget.dataset.url);
    })
    //*** */

    // should be in separate controller?
    //*** Dropdowns (should be in separate controller?) */
    document.querySelectorAll('.dropdown.filter').forEach((el) => {
      el.addEventListener('hide.bs.dropdown', function (e) {
        // Prevent to close dropdown when datetimepiker is visisble
        if (document.querySelector('.flatpickr-calendar.open')) { e.preventDefault() }
      })
      
      el.querySelector('.submit-filter').addEventListener('click', (e) => {
        // Add active class if any of the filter items selected
        const filterEl = e.currentTarget.parentElement.parentElement.parentElement;
        const hasFilter = Array.from(filterEl.querySelectorAll('input[type="text"],select')).some((el) => el.value)
        hasFilter ? filterEl.classList.add('active') : filterEl.classList.remove('active')
      })

      el.querySelector('.clear-filter').addEventListener('click', (e) => {
        const filterEl = e.currentTarget.parentElement;
        filterEl.querySelectorAll('input[type="text"],select').forEach((el) => el.value = null)
        filterEl.querySelector('.submit-filter').click()
      })
    })
  }
}