import { Controller } from "@hotwired/stimulus"

const TIME_OUT = 5000;
const INTERVAL = 10;

export default class extends Controller {  
  static targets = ['waitingBar']

  connect() {
    this.width = 100
    this.step  = this.width * INTERVAL / TIME_OUT

    this.intervalFn = setInterval(() => {
      this.changeWaitingBar();
    }, INTERVAL)
  }

  changeWaitingBar() {
    if (this.element.matches(':hover')) return

    this.width -= this.step;
    this.waitingBarTarget.style.width = `${this.width}%`
    if (this.width <= 0) { this.remove() }
  }

  remove() {
    clearInterval(this.intervalFn)

    this.element.classList.add('close')
    setTimeout(() => { this.element.remove() }, 1200) // Needs for animation
  }
}
